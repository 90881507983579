<template>
	<div class="banner">
        <v-app id="inspire">
            <v-row>
                <v-col md="10" offset-sm="1">

                    <v-row>
                        <v-col cols="10">
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                item-key="id"
                                hide-default-footer
                            >
                                <template v-slot:[`item.actions`]>
                                    <v-icon
                                        small
                                        class="mr-2"
                                    >
                                    mdi-menu
                                    </v-icon>
                                </template>
                            </v-data-table>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="2" class="d-flex align-center">
                            <v-btn color="primary" @click="updateSort">แก้ไข</v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn color="primary" @click="showAdd">เพิ่ม Banner</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-data-table
                                :headers="headerBanner"
                                :items="banners"
                                class="elevation-1"
                                >
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-btn icon @click="showEdit(item)">
                                        <v-icon>mdi-square-edit-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="carouselDialog(item)">
                                        <v-icon v-if="item.active=='Y'">mdi-arrow-down-bold-outline</v-icon>
                                        <v-icon v-else>mdi-arrow-up-bold-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="deleteDialog(item)">
                                        <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>

            <v-dialog v-model="dialogManage" persistent max-width="500px">
                <v-card>
                <v-card-title class="text">จัดการ Banner</v-card-title>
                <v-card-text>
                    <v-form v-model="verify" ref="form" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field label="ชื่อ Banner" :rules="rulesBlank" v-model="itemEdit.bname"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field label="Link" :rules="rulesBlank" v-model="itemEdit.blink"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-file-input
                                    accept=".jpg"
                                    label="รูปภาพ"
                                    v-model="inputFile"
                                ></v-file-input>
                                <img v-if="itemEdit.bid" :src="'https://line.carigoapp.com/images/banner/banner_'+itemEdit.bid+'.jpg'" width="300px">
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialogManage = false">ยกเลิก</v-btn>
                    <v-btn color="primary" depressed @click="manage()">ตกลง</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCar" persistent max-width="500px">
                <v-card>
                    <v-card-title v-if="carParam=='Y'" class="text">คุณต้องการนำ Banner นี้ลงใช่หรือไม่</v-card-title>
                    <v-card-title v-else class="text">คุณต้องการนำ Banner นี้ขึ้นใช่หรือไม่</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="dialogCar = false">ยกเลิก</v-btn>
                        <v-btn color="primary" depressed @click="editCarousel">ใช่</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDel" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text">คุณต้องการลบ Banner {{delParam}} ใช่หรือไม่</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="dialogDel = false">ยกเลิก</v-btn>
                        <v-btn color="primary" depressed @click="deleteBanner">ใช่</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="alert" persistent max-width="500px">
                <v-card>
                    <v-card-title class="text">{{message}}</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="alert = false">ปิด</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-app>
	</div>
</template>

<script>

	export default {
		name: 'Banner',
        data: function() {
            return {
                enabled: true,
                dragging: false,
                headers: [
                    {
                        text: 'จัดอันดับ Banner',
                        sortable: false,
                        value: 'bname',
                        width: '250'
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'blink',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                    },
                ],
                headerBanner: [
                    { text: 'Banner Name', value: 'bname'},
                    { text: 'Link', value: 'blink'},
                    { text: 'Active', value: 'active', width: '100'},
                    { text: 'Action', value: 'actions', width: '150'},
                ],
                items: [],
                banners: [],
                itemEdit: {},
                inputFile: null,
                verify: true,
                rulesBlank: [
                    v => !!v || 'Required',
                ],
                alert: false,
                dialogManage: false,
                dialogDel: false,
                delParam: '',
                delID: '',
                dialogCar: false,
                carParam: '',
                carID: '',
                message: '',
                color: '',
            };
        },

        beforeMount() {
            this.getCarusels();
			this.getBanner();
        },

        computed: {
            draggingInfo() {
            return this.dragging ? "under drag" : "";
            }
        },

        methods: {

			getCarusels: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("mode", 'getCarousels')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.items = response.data
                    let table = document.getElementsByTagName('tbody')[0];

                    app.$Sortable.create(table, {
                        onEnd({
                            newIndex,
                            oldIndex
                        }) {
                            app.items.splice(
                                newIndex,
                                0,
                                ...app.items.splice(oldIndex, 1)
                            );
                        },
                        animation: 150,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

			updateSort: async function () {
                var app = this;
				var id = []
				app.items.forEach(element => id.push(element.bid));
				var formData = new FormData();
				formData.append("id", id)
                formData.append("mode", 'updateSort')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
					app.alert = true;
					app.message = response.data;
                    console.log(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

			getBanner: async function () {
                var app = this;
				var formData = new FormData();
                formData.append("mode", 'getBanners')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.banners = response.data
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

			showAdd: async function () {
                var app = this;
				app.dialogManage = true
				app.itemEdit = {}
				app.inputFile = null
				if (this.$refs.form) {
					this.$refs.form.resetValidation()
				}
            },

            showEdit: async function (item) {
                var app = this;
                app.dialogManage = true
				app.inputFile = null;
                app.itemEdit = Object.assign({}, item)
                if (this.$refs.form) {
                    this.$refs.form.resetValidation()
                }
            },

            manage: async function () {
                if(!this.$refs.form.validate()) return;
                var app = this;
                var formData = new FormData();
                if(app.itemEdit.bid) formData.append("bid", app.itemEdit.bid)
                if(app.itemEdit.bname) formData.append("bname", app.itemEdit.bname)
                if(app.itemEdit.blink) formData.append("blink", app.itemEdit.blink)
				if(app.inputFile) formData.append("inputFile", app.inputFile)
                formData.append("mode", 'manage')
                // for (var value of formData.values()) {
                //     console.log(value);
                // }
                await this.$axios.post(this.$hostUrl+'get/', formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.alert = true
                    }else{
                        app.dialogManage = false
                        app.alert = true;
                        app.message = response.data;
                        app.getCarusels();
						app.getBanner();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogManage = false
                });
            },

            deleteDialog: function(obj){
                this.delParam = obj.bname;
                this.delID = obj.bid;
                this.dialogDel = true;
            },

            deleteBanner:async function(){
                var app = this;
                this.dialogDel = false;
                let formData = new FormData();
                if(app.delID) formData.append('bid', app.delID);
                formData.append('status', 'D');
                formData.append('mode', 'manage');
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.alert = true;
                        app.message = response.data;
                        app.delID = '';
                        app.getCarusels();
						app.getBanner();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

			carouselDialog: function(obj){
                this.carParam = obj.active;
                this.carID = obj.bid;
                this.dialogCar = true;
            },

			editCarousel:async function(){
                var app = this;
                this.dialogCar = false;
                let formData = new FormData();
                formData.append('bid', app.carID);
				if(this.carParam == 'Y') {
					formData.append('mode', 'deleteCarousel');
				} else {
					formData.append('mode', 'insertCarousel');
				}
                
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        alert("ERROR Please contact admin")
                    }else{
                        app.alert = true;
                        app.message = response.data;
                        app.delID = '';
                        app.getCarusels();
						app.getBanner();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },
		},
	}
</script>
