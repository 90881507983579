<template>
	<div class="Users">
        <v-app id="inspire">
            <v-row>
                <v-col offset-md="3" md="6">
                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            sort-by="username"
                            class="elevation-1"
                            >

                            <template v-slot:top>
                                <v-form v-model="valid" ref="form" lazy-validation>
                                    <v-toolbar flat>

                                        <v-toolbar-title>MANAGE USERS</v-toolbar-title>
                                        <v-spacer></v-spacer>

                                        <!----------------Dialog Add User ----------------->
                                        <v-dialog
                                            v-model="dialog"
                                            max-width="600px"
                                            persistent
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                Add User
                                            </v-btn>
                                            </template>
                                            <v-card>
                                            <v-card-title>
                                                <span class="text-h5">{{ formTitle }}</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                <v-row>
                                                    <v-col cols="12" md="7">
                                                        <v-text-field v-model="editedItem.username" label="Username*" :rules="nameRules" required :readonly="usernameRead"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="6" v-show="showPass">
                                                        <v-switch v-model="editedItem.resetPass" label="Reset Password"></v-switch>
                                                    </v-col>
                                                </v-row>
                                                </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="close">
                                                    Cancel
                                                </v-btn>
                                                <v-btn color="blue darken-1" text @click="save(formTitle)">
                                                    Save
                                                </v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </v-dialog>

                                        <!----------------End Dialog Add User ----------------->

                                        <!----------------Dialog Delete User ----------------->
                                        <v-dialog v-model="dialogDelete" max-width="500px">
                                            <v-card>
                                            <v-card-title class="text-h5">Are you sure?</v-card-title>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                                <v-btn color="blue darken-1" text @click="save('del')">OK</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                        <!----------------Dialog Delete User ----------------->

                                    </v-toolbar>
                                </v-form>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small class="mr-2" @click="editItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small @click="deleteItem(item)" v-show="showDelete">
                                    mdi-delete
                                </v-icon>
                            </template>

                            <template v-slot:no-data>
                                no data
                            </template>

                        </v-data-table>
                    </template>

                    <v-snackbar v-model="alert" top right :color="color">
                        {{ message }}
                        <template>
                            <v-btn text small fab @click="alert = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                        </template>
                    </v-snackbar>

                </v-col>
            </v-row>
        </v-app>
	</div>
</template>

<script>

	export default {
		name: 'Users',
        data: function() {
            return {
                valid: true,
                dialog: false,
                dialogDelete: false,
                headers: [
                    { text: 'Username',align: 'start',sortable: true,value: 'username' },
                    { text: 'Actions', value: 'actions', sortable: false },
                ],
                items: [],
                editedIndex: -1,
                editedItem: {
                    username: '',
                    status: 'E',
                    resetPass: false,
                },
                defaultItem: {
                    username: '',
                    status: 'E',
                    resetPass: false,
                },
                nameRules: [
                    v => !!v || 'Required',
                ],
                showDelete:false,
                usernameRead:false,
                showPass:false,
                alert:false,
                message:'',
                color:'',
            };
        },

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'ADD' : 'EDIT'
            },
        },

        watch: {
            dialog (val) {
                if (this.$refs.form) {
                    this.$refs.form.resetValidation()
                }
                if( this.editedItem.username == ''){
                    this.usernameRead = false
                    this.showPass = false
                }else {
                    this.usernameRead = true
                    this.showPass = true
                }
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },

        created () {
            this.initialize()
        },

        methods: {
            initialize () {
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getAllUser');
                this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = "ERROR Please contact admin"
                        app.color = "error"
                        app.alert = true
                    }else{
                        app.items = response.data 
                    }
                })
                .catch(function (error) {
                    app.message = error
                    app.color = "error"
                    app.alert = true
                });
            },

            editItem (item) {
                this.editedIndex = this.items.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                this.editedIndex = this.items.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                })
            },

            deleteItemConfirm () {
                // this.items.splice(this.editedIndex, 1)
                console.log(this.items[this.editedIndex].username)
                this.closeDelete()
            },

            save (mode) {
                var app = this;
                if(mode != 'del')
                    if(!this.$refs.form.validate()) return;
                // if (this.editedIndex > -1) {
                // Object.assign(this.items[this.editedIndex], this.editedItem)
                // } else {
                // this.items.push(this.editedItem)
                // }
                var statusTmp = app.editedItem.status
                var modeTmp = 'updateUser'
                if(mode == 'del') statusTmp = 'D'
                if(mode == 'ADD') modeTmp = 'addUser'
                if(app.editedItem.resetPass) statusTmp = 'R'
                let formData = new FormData();
                formData.append('username', app.editedItem.username);
                formData.append('status', statusTmp);
                formData.append('mode', modeTmp);
                // console.log([...formData])
                this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = "ERROR Please contact admin"
                        app.color = "error"
                        app.alert = true
                    }else{
                        var output = response.data 
                        if(output == 'exist'){
                            app.message = "This user already exist"
                            app.color = "error"
                            app.alert = true
                        } else {
                            console.log(output)
                            app.message = "Manage user success"
                            app.color = "success"
                            app.alert = true
                            app.initialize()
                            if(app.mode == 'del'){
                                app.closeDelete()
                            }else{
                                app.close()
                            }
                        }
                    }
                })
                .catch(function (error) {
                    app.message = error
                    app.color = "error"
                    app.alert = true
                });

            },
        }
	}
</script>
