<template>
	<div class="import">
        <v-app id="inspire">
            <v-container>
            <v-row>
                <v-col offset-md="2" md="8" offset-sm="1">

                    <v-row>
                        <v-col><h2>นำเข้าข้อมูล</h2></v-col>
                    </v-row>

                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-file-input
                            accept=".xls,.xlsx"
                            label="File input"
                            v-model="inputFile"
                            clearable
                        ></v-file-input>
                        <v-radio-group
                            v-model="data"
                            row
                        >
                        <v-radio
                            label="Member"
                            value="cus"
                        ></v-radio>
                        <v-radio
                            label="รถยนต์"
                            value="car"
                        ></v-radio>
                        <v-radio
                            label="กรมธรรม์"
                            value="ins"
                        ></v-radio>
                        <v-radio
                            label="บริการ"
                            value="his"
                        ></v-radio>
                        <v-radio
                            label="เบอร์โทรฉุกเฉิน"
                            value="emer"
                        ></v-radio>
                        <v-radio
                            label="Application"
                            value="app"
                        ></v-radio>
                        </v-radio-group>
                        <v-btn block 
                            color="primary"
                            :loading="loading"
                            :disabled="loading"
                            @click="importFile()"
                        >
                            ตกลง
                        </v-btn>

                        <br>
                        <b>ตัวอย่างไฟล์ Import <a href="/import.xlsx">Click</a></b>
                        <v-img
                            :src="'../images/'+data+'.png'"
                            width="100%"
                        ></v-img>

                        <br>
                        <div v-if="data=='emer'"><b>รายชื่อจังหวัดใน Nostra <a href="https://developer.nostramap.com/developer/V2/live/testing/administrativeCode.html" target="_blank">Click</a></b></div>
                    </v-form>

                </v-col>
            </v-row>
            </v-container>

            <v-snackbar v-model="alert" top right :color="color">
                {{ message }}
                <template>
                    <v-btn text small fab @click="alert = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                </template>
            </v-snackbar>

        </v-app>
	</div>
</template>

<script>

	export default {
		name: 'Import',
        data: function() {
            return {
                valid: true,
                alert: false,
                message: '',
                color: '',
                loading: false,
                data: 'cus',
                inputFile: null,
            };
        },

        methods: {
            importFile: async function(){
                var app = this;
                if(app.inputFile){
                    app.loading = true
                    var formData = new FormData();
                    formData.append("inputFile", app.inputFile)
                    formData.append("data", app.data)
                    formData.append("mode", 'import')
                    await this.$axios.post(this.$hostUrl+'get/', formData,
                    {
                        headers: {'Content-Type': 'multipart/form-data'}
                    })
                    .then(function (response) {
                        if(!response.data){
                            app.message = response
                            app.color = "error" //success
                            app.alert = true
                        }else{
                            app.message = response.data
                            app.color = "success" //success
                            app.alert = true
                            app.inputFile = null
                        }
                        app.loading = false
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                } else {
                    app.message = "กรุณาเลือกไฟล์ที่ต้องการนำเข้า"
                    app.color = "error" //success
                    app.alert = true
                }
                
            },
        }
	}
</script>
