<template>
	<div>
        <v-app id="inspire">
            <v-row>
                <v-col>

                    <v-row>
                        <v-col><h2>จัดการข้อมูล</h2></v-col>
                    </v-row>

                    <v-form>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field label="เบอร์โทรศัพท์" v-model="phone" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field label="ชื่อ นามสกุล" v-model="firstname" clearable></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12" md="4">
                                <v-text-field label="E-Mail" v-model="surname"></v-text-field>
                            </v-col> -->
                        </v-row>
                        <v-btn block 
                            color="primary"
                            :loading="loading"
                            :disabled="loading"
                            @click="search()"
                        >
                            ค้นหา
                        </v-btn>
                    </v-form>

                    <br>

                    <v-data-table
                        :headers="headers"
                        :items="customers"
                        sort-by="cardid"
                        class="elevation-1"
                        >
                        <template v-slot:top>

            <!-- //---------------------------- Dialog Referent ------------------------------// -->

                            <v-dialog v-model="dialogGenerate" persistent max-width="500px">
                                <v-card>
                                <v-card-title class="text">คุณต้องการสร้างรหัสอ้างอิงใช่หรือไม่?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="dialogGenerate = false">ยกเลิก</v-btn>
                                    <v-btn color="primary" depressed @click="generate()">ตกลง</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>

            <!-- //---------------------------- Dialog Del Insure ------------------------------// -->

                            <v-dialog v-model="dialogDelInsure" persistent max-width="500px">
                                <v-card>
                                <v-card-title class="text">คุณต้องการลบกรมธรรม์นี้ใช่หรือไม่?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="dialogDelInsure = false">ยกเลิก</v-btn>
                                    <v-btn color="primary" depressed @click="delInsure()">ตกลง</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>

            <!-- //---------------------------- Dialog Del Car ------------------------------// -->

                            <v-dialog v-model="dialogDelCar" persistent max-width="500px">
                                <v-card>
                                <v-card-title class="text">คุณต้องการลบรถคันนี้ใช่หรือไม่?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="dialogDelCar = false">ยกเลิก</v-btn>
                                    <v-btn color="primary" depressed @click="delCar()">ตกลง</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>

            <!-- //---------------------------- Dialog Del Service ------------------------------// -->

                            <v-dialog v-model="dialogDelService" persistent max-width="500px">
                                <v-card>
                                <v-card-title class="text">คุณต้องการลบบริการนี้ใช่หรือไม่?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="dialogDelService = false">ยกเลิก</v-btn>
                                    <v-btn color="primary" depressed @click="delService()">ตกลง</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>

            <!-- //---------------------------- Dialog Edit ------------------------------// -->

                            <v-dialog v-model="dialogEdit" persistent max-width="800px">
                                <v-card>
                                <v-card-title class="text">แก้ไขข้อมูล</v-card-title>
                                <v-card-text>
                                    <v-form v-model="verifyEdit" ref="formEdit" lazy-validation>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-text-field label="รหัสสมาชิก" v-model="itemEdit.cardid" readonly></v-text-field>
                                            </v-col>
                                            <!-- <v-col cols="12" md="2">
                                                <v-text-field label="คำนำหน้า" :rules="rulesBlank" v-model="itemEdit.title"></v-text-field>
                                            </v-col> -->
                                            <v-col cols="12" md="6">
                                                <v-text-field label="ชื่อ นามสกุล" :rules="rulesBlank" v-model="itemEdit.firstname"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field label="E-Mail" :rules="rulesBlank" v-model="itemEdit.surname"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-menu
                                                    v-model="pickerBD"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field v-model="itemEdit.birthday" label="วันเกิด" prepend-icon="mdi-calendar"
                                                        readonly v-bind="attrs" v-on="on"></v-text-field>
                                                    </template>
                                                    <v-date-picker 
                                                        v-model="birthday" 
                                                        @input="closeBD"
                                                        year-icon="mdi-calendar-blank"
                                                        locale="th"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field label="โทรศัพท์" :rules="rulesBlank" v-model="itemEdit.phone"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-textarea
                                                    v-model="itemEdit.address"
                                                    label="ที่อยู่"
                                                ></v-textarea>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field label="ผู้ดูแล" v-model="itemEdit.caretaker"></v-text-field>
                                                <v-select
                                                    v-model="itemEdit.roles"
                                                    :items="roles"
                                                    hint=""
                                                    label="Role"
                                                    item-text="text"
                                                    item-value="val"
                                                    persistent-hint
                                                    return-object
                                                    style="font-size: 1em"
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red darken-1" text @click="dialogEdit = false">ยกเลิก</v-btn>
                                    <v-btn color="primary" depressed @click="edit()">ตกลง</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>

            <!-- //---------------------------- Dialog Insure ------------------------------// -->

                            <v-dialog v-model="dialogInsure" persistent max-width="900px">
                                <v-card>
                                <v-card-text>
                                    <v-form>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-toolbar flat>
                                                    <v-toolbar-title>รายละเอียดกรมธรรม์</v-toolbar-title>
                                                    <v-spacer></v-spacer>

            <!-- //---------------------------- Dialog Insure Manage ------------------------------// -->

                                                    <v-dialog v-model="dialogInsureManage" persistent max-width="800px">
                                                        <v-card>
                                                        <v-card-title class="text">จัดการกรมธรรม์</v-card-title>
                                                        <v-card-text>
                                                            <v-form v-model="verifyInsure" ref="formInsure" lazy-validation>
                                                                <v-row>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="บริษัทประกัน" :rules="rulesBlank" v-model="itemInsEdit.company"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-select :items="types" item-text="tname" item-value="tid" :rules="rulesBlank" 
                                                                        v-model="itemInsEdit.tid" v-on:change="changeType" label="ประเภทประกัน"></v-select>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="หมายเลขกรมธรรม์" :rules="rulesBlank" v-model="itemInsEdit.insid"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="ประเภทกรมธรรม์" :rules="rulesBlank" v-model="itemInsEdit.insname"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-menu
                                                                            v-model="pickerIS"
                                                                            :close-on-content-click="false"
                                                                            :nudge-right="40"
                                                                            transition="scale-transition"
                                                                            offset-y
                                                                            min-width="auto"
                                                                        >
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                            <v-text-field v-model="itemInsEdit.insstart" label="วันที่เริ่มกรมธรรม์" :rules="rulesBlank" prepend-icon="mdi-calendar"
                                                                                readonly v-bind="attrs" v-on="on"></v-text-field>
                                                                            </template>
                                                                            <v-date-picker 
                                                                                v-model="insstart" 
                                                                                @input="closeIS"
                                                                                year-icon="mdi-calendar-blank"
                                                                                locale="th"
                                                                            ></v-date-picker>
                                                                        </v-menu>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-menu
                                                                            v-model="pickerIE"
                                                                            :close-on-content-click="false"
                                                                            :nudge-right="40"
                                                                            transition="scale-transition"
                                                                            offset-y
                                                                            min-width="auto"
                                                                        >
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                            <v-text-field v-model="itemInsEdit.insend" label="วันที่สิ้นสุดกรมธรรม์" :rules="rulesBlank" prepend-icon="mdi-calendar"
                                                                                readonly v-bind="attrs" v-on="on"></v-text-field>
                                                                            </template>
                                                                            <v-date-picker 
                                                                                v-model="insend" 
                                                                                @input="closeIE"
                                                                                year-icon="mdi-calendar-blank"
                                                                                locale="th"
                                                                            ></v-date-picker>
                                                                        </v-menu>
                                                                    </v-col>
                                                                    <v-col cols="12" md="12" v-show="showCar">
                                                                        <v-select :items="cars" :item-text="cars => cars.brand+' '+cars.model+' '+cars.carregis+' '+cars.carprovince"
                                                                            item-value="vid" :rules="rulesBlankCar" v-model="itemInsEdit.vid" label="รายละเอียดรถ"></v-select>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-textarea
                                                                            v-model="itemInsEdit.insdetail"
                                                                            label="รายละเอียดกรมธรรม์" :rules="rulesBlank"
                                                                        ></v-textarea>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="ไฟล์กรมธรรม์" v-model="itemInsEdit.inspdf"></v-text-field>
                                                                        <v-btn text :href="itemInsEdit.inspdf" target="_blank" v-show="showpdf">
                                                                            Show PDF
                                                                            <v-icon large>
                                                                                mdi-file-pdf-box
                                                                            </v-icon>
                                                                        </v-btn>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-form>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="red darken-1" text @click="dialogInsureManage = false">ยกเลิก</v-btn>
                                                            <v-btn color="primary" depressed @click="manageInsure()">ตกลง</v-btn>
                                                        </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>

                                                    <v-btn color="primary" depressed @click="showAddInsure()">เพิ่มกรมธรรม์</v-btn>
                                                </v-toolbar>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <v-data-table
                                                    :headers="headersInsure"
                                                    :items="insures"
                                                    sort-by="iid"
                                                    class="elevation-1"
                                                >
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    small
                                                                    class="mr-2"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @click="showEditInsure(item)"
                                                                >
                                                                mdi-pencil
                                                                </v-icon>
                                                            </template>
                                                            <span>แก้ไขข้อมูล</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    small
                                                                    class="mr-2"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @click="showDelInsure(item)"
                                                                >
                                                                mdi-trash-can-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>ลบข้อมูล</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="dialogInsure = false">ปิด</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>

            <!-- //---------------------------- Dialog Car ------------------------------// -->

                            <v-dialog v-model="dialogCar" persistent max-width="800px">
                                <v-card>
                                <v-card-text>
                                    <v-form>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-toolbar flat>
                                                    <v-toolbar-title>รายละเอียดรถ</v-toolbar-title>
                                                    <v-spacer></v-spacer>

                            <!-- //---------------------------- Dialog Car Manage ------------------------------// -->

                                                    <v-dialog v-model="dialogCarManage" persistent max-width="800px">
                                                        <v-card>
                                                        <v-card-title class="text">จัดการรถ</v-card-title>
                                                        <v-card-text>
                                                            <v-form v-model="verifyCar" ref="formCar" lazy-validation>
                                                                <v-row>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="เจ้าของ" v-model="itemCarEdit.owner"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="เบอร์โทร" v-model="itemCarEdit.mobile"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="ยี่ห้อ" :rules="rulesBlank" v-model="itemCarEdit.brand"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="รุ่น" :rules="rulesBlank" v-model="itemCarEdit.model"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-text-field label="ทะเบียนรถ" :rules="rulesBlank" v-model="itemCarEdit.carregis"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-autocomplete
                                                                            ref="itemCarEdit.carprovince"
                                                                            v-model="itemCarEdit.carprovince"
                                                                            :items="carprovinces"
                                                                            label="จังหวัด"
                                                                        ></v-autocomplete>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-select
                                                                            v-model="cartype"
                                                                            :items="itemsType"
                                                                            hint=""
                                                                            label="ประเภทรถยนต์"
                                                                            item-text="text"
                                                                            item-value="val"
                                                                            persistent-hint
                                                                            return-object
                                                                            v-on:change="showEngine"
                                                                            required
                                                                            style="font-size: 1em"
                                                                        ></v-select>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6" v-show="show == '1' || show == '4'">
                                                                        <v-text-field label="ขนาดเครื่องยนต์ (cc)" v-model="itemCarEdit.carsize" type="number"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6" v-show="show == '2' || show == '3'">
                                                                        <v-text-field label="น้ำหนักเครื่องยนต์ (kg)" v-model="itemCarEdit.carsize" type="number"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-menu
                                                                            v-model="pickerDC"
                                                                            :close-on-content-click="false"
                                                                            :nudge-right="40"
                                                                            transition="scale-transition"
                                                                            offset-y
                                                                            min-width="auto"
                                                                        >
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                            <v-text-field v-model="itemCarEdit.cardate" label="วันที่จดทะเบียน" prepend-icon="mdi-calendar"
                                                                                readonly v-bind="attrs" v-on="on"></v-text-field>
                                                                            </template>
                                                                            <v-date-picker 
                                                                                v-model="dateDC" 
                                                                                @input="closeDC"
                                                                                year-icon="mdi-calendar-blank"
                                                                                locale="th"
                                                                                :max="maxDate"
                                                                            ></v-date-picker>
                                                                        </v-menu>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6">
                                                                        <v-select
                                                                            v-model="itemCarEdit.lastpaid"
                                                                            :items="years"
                                                                            label="วันสิ้นอายุภาษีปีล่าสุด (ใช้คำนวณภาษี)"
                                                                        >
                                                                        <template slot="no-data"><font class="pa-5">กรุณาเลือกวันที่จดทะเบียน</font></template>
                                                                        </v-select>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6" v-show="show != '4'">
                                                                        <v-switch
                                                                            v-model="itemCarEdit.ngv"
                                                                            label="ติดแก๊ส NGV"
                                                                        ></v-switch>
                                                                    </v-col>
                                                                    <v-col cols="12" md="6" v-show="show == '1'">
                                                                        <v-switch
                                                                            v-model="itemCarEdit.legal"
                                                                            label="จดทะเบียนนิติบุคคล"
                                                                        ></v-switch>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-form>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="red darken-1" text @click="dialogCarManage = false">ยกเลิก</v-btn>
                                                            <v-btn color="primary" depressed @click="manageCar()">ตกลง</v-btn>
                                                        </v-card-actions>
                                                        </v-card>
                                                    </v-dialog>

                                                    <v-btn color="primary" depressed @click="showAddCar()">เพิ่มรถ</v-btn>
                                                </v-toolbar>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <v-data-table
                                                    :headers="headersCar"
                                                    :items="cars"
                                                    sort-by="vid"
                                                    class="elevation-1"
                                                >
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    small
                                                                    class="mr-2"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @click="showEditCar(item)"
                                                                >
                                                                mdi-pencil
                                                                </v-icon>
                                                            </template>
                                                            <span>แก้ไขข้อมูล</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    small
                                                                    class="mr-2"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @click="showDelCar(item)"
                                                                >
                                                                mdi-trash-can-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>ลบข้อมูล</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="dialogCar = false">ปิด</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>

            <!-- //---------------------------- Dialog Service ------------------------------// -->

                            <v-dialog v-model="dialogService" persistent max-width="800px">
                                <v-card>
                                <v-card-text>
                                    <v-form>
                                        <v-row>
                                            <v-col cols="12" md="12">
                                                <v-toolbar flat>
                                                    <v-toolbar-title>รายละเอียดบริการ</v-toolbar-title>
                                                    <v-spacer></v-spacer>
                                                </v-toolbar>
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <v-data-table
                                                    :headers="headersService"
                                                    :items="service"
                                                    sort-by="vid"
                                                    class="elevation-1"
                                                >
                                                    <template v-slot:[`item.actions`]="{ item }">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                    small
                                                                    class="mr-2"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @click="showDelService(item)"
                                                                >
                                                                mdi-trash-can-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>ลบข้อมูล</span>
                                                        </v-tooltip>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="dialogService = false">ปิด</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>

            <!-- //---------------------------- Dialog Message ------------------------------// -->

                            <v-dialog v-model="dialogMsg" persistent max-width="500px">
                                <v-card>
                                <v-card-title class="text">{{msgDialog}}</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="dialogMsg = false">ปิด</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </template>

            <!-- //---------------------------- Icon button ------------------------------// -->

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="showEdit(item)"
                                    >
                                    mdi-pencil
                                    </v-icon>
                                </template>
                                <span>แก้ไขข้อมูล</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="showCars(item)"
                                    >
                                    mdi-car
                                    </v-icon>
                                </template>
                                <span>จัดการรถ</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="showInsure(item)"
                                    >
                                    mdi-text-box-search-outline
                                    </v-icon>
                                </template>
                                <span>จัดการกรมธรรม์</span>
                            </v-tooltip>
                            <!-- <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="showGenerate(item)"
                                    >
                                    mdi-qrcode-edit
                                    </v-icon>
                                </template>
                                <span>สร้างรหัสอ้างอิง</span>
                            </v-tooltip> -->
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="showService(item)"
                                    >
                                    mdi-wrench
                                    </v-icon>
                                </template>
                                <span>จัดการบริการ</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>

                </v-col>
            </v-row>

            <v-snackbar v-model="alert" top right :color="color">
                {{ message }}
                <template>
                    <v-btn text small fab @click="alert = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                </template>
            </v-snackbar>

        </v-app>
	</div>
</template>

<script>

	export default {
		name: 'Manage',
        data: function() {
            return {
                valid: true,
                alert: false,
                message: '',
                color: '',
                loading: false,
                phone: '',
                firstname: '',
                surname: '',
                dialogGenerate: false,
                dialogEdit: false,
                dialogInsure: false,
                dialogInsureManage: false,
                dialogDelInsure: false,
                dialogCar: false,
                dialogCarManage: false,
                dialogDelCar: false,
                dialogService: false,
                dialogDelService: false,
                dialogMsg: false,
                msgDialog: '',
                verifyEdit: true,
                verifyInsure: true,
                verifyCar: true,
                rulesBlank: [
                        v => !!v || 'Required',
                    ],
                rulesBlankCar: [
                        v =>{
                            if(this.itemInsEdit.tid != '1') return true
                            return !!v || 'Required'
                        } 
                    ],
                headers: [
                    { text: 'รหัสสมาชิก', value: 'cardid'},
                    { text: 'ชื่อ นามสกุล', value: 'firstname' },
                    { text: 'โทรศัพท์', value: 'phone' },
                    { text: 'วันที่นำเข้า', value: 'createdate' },
                    { text: 'Line', value: 'line' },
                    { text: 'รหัสอ้างอิง', value: 'refercode' },
                    { text: 'Role', value: 'roles' },
                    { text: 'จัดการ', value: 'actions', sortable: false },
                ],
                customers: [],
                headersInsure: [
                    { text: 'ทะเบียนรถ', value: 'car'},
                    { text: 'บริษัทประกัน', value: 'company'},
                    { text: 'ประเภทกรมธรรม์', value: 'insname'},
                    { text: 'วันสิ้นสุดกรมกรรม์', value: 'insend' },
                    { text: 'จัดการ', value: 'actions', sortable: false },
                ],
                headersCar: [
                    { text: 'ยี่ห้อ', value: 'brand'},
                    { text: 'รุ่น', value: 'model' },
                    { text: 'ทะเบียนรถ', value: 'carregis' },
                    { text: 'จังหวัด', value: 'carprovince' },
                    { text: 'จัดการ', value: 'actions', sortable: false },
                ],
                headersService: [
                    { text: 'ทะเบียนรถ', value: 'carregis'},
                    { text: 'บริการ', value: 'name' },
                    { text: 'ชื่อร้าน', value: 'store' },
                    { text: 'วันที่ใช้บริการ', value: 'startdate' },
                    { text: 'จัดการ', value: 'actions', sortable: false },
                ],
                insures: [],
                cars: [],
                service: [],
                itemEdit: {},
                itemInsEdit: {},
                itemCarEdit: {},
                itemServiceEdit: {},
                types:[],
                carprovinces:[],
                pickerBD: false,
                birthday: null,
                pickerIS: false,
                insstart: null,
                pickerIE: false,
                insend: null,
                showpdf: false,
                showCar: false,
                inspdf: '',
                inspdfLink: '',
                itemsType: [
                    { text: 'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รถเก๋ง)', desc: 'เช่น รถกระบะ 4 ประตู รถเก๋ง', val: '1' },
                    { text: 'รถยนต์บรรทุกส่วนบุคคล (รถกระบะ)', desc: 'เช่น รถตู้', val: '2' },
                    { text: 'รถยนต์นั่งส่วนบุคคลเกิน 7 คน (รถตู้)', desc: 'เช่น รถกระบะ 2 ประตู', val: '3' },
                    { text: 'รถจักรยานยนต์ (มอเตอร์ไซค์)', desc: '', val: '4' },
                ],
                years: [],
                cartype:  { },
                pickerDC: false,
                dateDC: null,
                maxDate: this.$moment(new Date()).format('YYYY-MM-DD'),
                show:'',
                roles: [
                    { text: 'Member', val: 'member' },
                    { text: 'Agent', val: 'agent' },
                ],
                role: {},
            };
        },

        beforeMount(){
            this.getProvince()
            this.getType()
        },

        methods: {
            search: async function () {
                var app = this;
                app.loading = true;
                var formData = new FormData();
                formData.append("phone", app.phone)
                formData.append("firstname", app.firstname)
                formData.append("surname", app.surname)
                formData.append("mode", 'searchCus')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.customers = response.data
                    }
                    app.loading = false
                })
                .catch(function (error) {
                    console.log(error);
                    app.loading = false
                });
            },

            closeBD (){
                this.pickerBD = false;
                //app.itemEdit.birthday = app.birthday ? moment(app.birthday).format('DD-MM-YYYY') : '';
                this.itemEdit.birthday = this.birthday ? this.$moment(this.birthday).add(543, 'year').format('YYYY-MM-DD') : '';
            },

            closeIS (){
                this.pickerIS = false;
                this.itemInsEdit.insstart = this.insstart ? this.$moment(this.insstart).add(543, 'year').format('YYYY-MM-DD') : '';
            },

            closeIE (){
                this.pickerIE = false;
                this.itemInsEdit.insend = this.insend ? this.$moment(this.insend).add(543, 'year').format('YYYY-MM-DD') : '';
            },

            showEdit (item) {
                this.dialogEdit = true
                this.itemEdit = Object.assign({}, item)
                if (this.$refs.formEdit) {
                    this.$refs.formEdit.resetValidation()
                }
            },

            edit: async function () {
                if(!this.$refs.formEdit.validate()) return;
                var app = this;
                var formData = new FormData();
                formData.append("cid", app.itemEdit.cid)
                formData.append("cardid", app.itemEdit.cardid)
                // formData.append("title", app.itemEdit.title)
                formData.append("firstname", app.itemEdit.firstname)
                formData.append("surname", app.itemEdit.surname)
                formData.append("birthday", app.itemEdit.birthday)
                formData.append("phone", app.itemEdit.phone)
                formData.append("address", app.itemEdit.address)
                formData.append("caretaker", app.itemEdit.caretaker)
                let role = app.itemEdit.roles.val?app.itemEdit.roles.val:app.itemEdit.roles;
                formData.append("roles", role)
                formData.append("mode", 'editCus')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.dialogEdit = false
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.msgDialog = response.data
                        app.dialogMsg = true
                        app.search()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogEdit = false
                });
            },

            showInsure: async function (item) {
                var app = this;
                app.dialogInsure = true
                app.itemEdit = Object.assign({}, item)
                var formData = new FormData();
                formData.append("cid", item.cid)
                formData.append("mode", 'searchIns')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        if(response.data.length > 0) app.insures = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            showAddInsure: async function () {
                var app =this;
                app.dialogInsureManage = true
                app.itemInsEdit = {}
                app.showpdf = false
                // app.inspdf = ''
                // app.inspdfLink = ''
                app.showCar = false
                if (this.$refs.formInsure) {
                    this.$refs.formInsure.resetValidation()
                }
                var formData = new FormData();
                formData.append("cid", app.itemEdit.cid)
                formData.append("mode", 'searchCars')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.cars = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            showEditInsure: async function (item) {
                var app = this;
                app.dialogInsureManage = true
                app.showpdf = false
                if(item.inspdf != '') {
                    app.showpdf = true
                    // app.inspdf = item.inspdf
                    // app.inspdfLink = '../document/'+item.cid+'/'+app.inspdf
                }
                app.itemInsEdit = Object.assign({}, item)
                // app.itemInsEdit.inspdf = null
                app.changeType()
                if (this.$refs.formInsure) {
                    this.$refs.formInsure.resetValidation()
                }
                var formData = new FormData();
                formData.append("cid", app.itemEdit.cid)
                formData.append("mode", 'searchCars')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.cars = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            manageInsure: async function () {
                if(!this.$refs.formInsure.validate()) return;
                var app = this;
                var formData = new FormData();
                formData.append("iid", app.itemInsEdit.iid)
                formData.append("cid", app.itemEdit.cid)
                formData.append("insid", app.itemInsEdit.insid)
                formData.append("insname", app.itemInsEdit.insname)
                formData.append("insstart", app.itemInsEdit.insstart)
                formData.append("insend", app.itemInsEdit.insend)
                formData.append("insdetail", app.itemInsEdit.insdetail)
                formData.append("inspdf", app.itemInsEdit.inspdf)
                // formData.append("fileName", app.inspdf)
                formData.append("tid", app.itemInsEdit.tid)
                formData.append("company", app.itemInsEdit.company)
                formData.append("vid", app.itemInsEdit.vid)
                formData.append("mode", 'manageIns')
                await this.$axios.post(this.$hostUrl+'get/', formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(function (response) {
                    app.dialogInsureManage = false
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.msgDialog = response.data
                        app.dialogMsg = true
                        app.showInsure(app.itemEdit)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogInsureManage = false
                });
            },

            showDelInsure (item) {
                var app = this;
                app.dialogDelInsure = true
                app.itemInsEdit = Object.assign({}, item)
            },

            delInsure: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("iid", app.itemInsEdit.iid)
                formData.append("mode", 'delInsure')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.dialogDelInsure = false
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.msgDialog = response.data
                        app.dialogMsg = true
                        app.showInsure(app.itemEdit)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogDelInsure = false
                });
            },

            showCars: async function (item) {
                var app = this;
                app.dialogCar = true
                app.itemEdit = Object.assign({}, item)
                var formData = new FormData();
                formData.append("cid", item.cid)
                formData.append("mode", 'searchCars')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.cars = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            showAddCar: async function () {
                var app = this;
                app.dialogCarManage = true
                app.itemCarEdit = {}
                app.show = '';
                app.cartype = {}
                app.years = []
                if (this.$refs.formCar) {
                    this.$refs.formCar.resetValidation()
                }
            },

            showEditCar: async function (item) {
                var app = this;
                app.inputFile = null;
                app.years = []
                app.dialogCarManage = true
                app.itemCarEdit = Object.assign({}, item)
                app.cartype = ''
                app.show = ''
                if(item.cartype != null) {
                    app.cartype = item.cartype
                    app.show = item.cartype
                }
                app.itemCarEdit.ngv = false;
                if(item.ngv != '0') app.itemCarEdit.ngv = true;
                app.itemCarEdit.legal = false;
                if(item.legal != '0') app.itemCarEdit.legal = true;
                app.dateDC = app.itemCarEdit.cardate ? this.$moment(app.itemCarEdit.cardate).add(-543, 'year').format('YYYY-MM-DD') : '';
                if(item.lastpaid) app.years.push(item.lastpaid)
                if (this.$refs.formCar) {
                    this.$refs.formCar.resetValidation()
                }
            },

            showEngine(){
                this.show = this.cartype.val;
            },

            closeDC (){
                this.pickerDC = false;
                this.itemCarEdit.lastpaid = '';
                this.itemCarEdit.cardate = this.dateDC ? this.$moment(this.dateDC).add(543, 'year').format('YYYY-MM-DD') : '';
                this.years = [];
                this.years.push('');
                for(var i=(new Date().getFullYear());i>=(this.dateDC ? this.$moment(this.dateDC).format('YYYY') : '');i--){
                    this.years.push(i+543);
                }
            },

            manageCar: async function () {
                if(!this.$refs.formCar.validate()) return;
                var app = this;
                var formData = new FormData();
                formData.append("vid", app.itemCarEdit.vid)
                formData.append("cid", app.itemEdit.cid)
                formData.append("brand", app.itemCarEdit.brand)
                formData.append("model", app.itemCarEdit.model)
                formData.append("carregis", app.itemCarEdit.carregis)
                formData.append("carprovince", app.itemCarEdit.carprovince)
                let cartype = app.cartype.val?app.cartype.val:(typeof app.cartype!='object')?app.cartype:'undefined';
                formData.append("cartype", cartype)
                formData.append("carsize", app.itemCarEdit.carsize)
                formData.append("cardate", app.itemCarEdit.cardate)
                formData.append("lastpaid", app.itemCarEdit.lastpaid)
                var ngv = '0';
                if(app.itemCarEdit.ngv && app.show != '4') ngv = '1';
                formData.append("ngv", ngv)
                var legal = '0';
                if(app.itemCarEdit.legal && app.show == '1') legal = '1';
                formData.append("legal", legal)
                formData.append("owner", app.itemCarEdit.owner)
                formData.append("mobile", app.itemCarEdit.mobile)
                formData.append("mode", 'manageCar')
                await this.$axios.post(this.$hostUrl+'get/', formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(function (response) {
                    app.dialogInsureManage = false
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.msgDialog = response.data
                        app.dialogMsg = true
                        app.showCars(app.itemEdit)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogCarManage = false
                });
            },

            showDelCar (item) {
                var app = this;
                app.dialogDelCar = true
                app.itemCarEdit = Object.assign({}, item)
            },

            delCar: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("vid", app.itemCarEdit.vid)
                formData.append("mode", 'delCar')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.dialogDelCar = false
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.msgDialog = response.data
                        app.dialogMsg = true
                        app.showCars(app.itemEdit)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogDelInsure = false
                });
            },

            showGenerate (item) {
                var app = this;
                app.dialogGenerate = true
                app.itemEdit = Object.assign({}, item)
            },

            generate: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("phone", app.itemEdit.phone)
                formData.append("mode", 'genRefer')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.dialogGenerate = false
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.msgDialog = response.data
                        app.dialogMsg = true
                        app.search()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogGenerate = false
                });
            },

            getProvince: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("mode", 'getProvince')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.carprovinces = response.data
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            getType: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("mode", 'getType')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.types = response.data
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            changeType: function() {
                var app = this;
                app.showCar = false
                if(app.itemInsEdit.tid == '1'){
                    app.showCar = true
                }
            },

            showService: async function (item) {
                var app = this;
                app.dialogService = true
                app.itemEdit = Object.assign({}, item)
                var formData = new FormData();
                formData.append("cid", item.cid)
                formData.append("mode", 'searchService')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.service = response.data
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            showDelService (item) {
                var app = this;
                app.dialogDelService = true
                app.itemServiceEdit = Object.assign({}, item)
            },

            delService: async function () {
                var app = this;
                var formData = new FormData();
                formData.append("hid", app.itemServiceEdit.hid)
                formData.append("mode", 'delService')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    app.dialogDelService= false
                    if(!response.data){
                        app.message = response
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.msgDialog = response.data
                        app.dialogMsg = true
                        app.showService(app.itemEdit)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    app.dialogDelInsure = false
                });
            },
        }
	}
</script>
