<template>
	<div class="import">
        <v-app id="inspire">
            <v-row>
                <v-col offset-md="2" md="8" offset-sm="1">

                    <v-row>
                        <v-col><h2>Export</h2></v-col>
                    </v-row>

                    <v-form v-model="valid" ref="form" lazy-validation>
                        <v-radio-group
                            v-model="data"
                            row
                        >
                        <v-radio
                            label="Member"
                            value="cus"
                        ></v-radio>
                        <v-radio
                            label="รถยนต์"
                            value="car"
                        ></v-radio>
                        <v-radio
                            label="กรมธรรม์"
                            value="insure"
                        ></v-radio>
                        <v-radio
                            label="บริการ"
                            value="his"
                        ></v-radio>
                        <v-radio
                            label="เบอร์โทรฉุกเฉิน"
                            value="emer"
                        ></v-radio>
                        <v-radio
                            label="Application"
                            value="app"
                        ></v-radio>
                        </v-radio-group>
                        <v-row v-if="data!='emer' && data!='app'">
                            <v-col cols="12" md="6">
                                <v-text-field label="เบอร์โทรศัพท์" v-model="phone" clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field label="ชื่อ นามสกุล" v-model="firstname" clearable></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="data=='cus'">
                            <v-col cols="12" md="4">
                                <v-select :items="users"
                                v-model="caretaker" label="ผู้ดูแล"></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-menu
                                    v-model="pickerCD"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="createdate" label="วันที่นำเข้า" prepend-icon="mdi-calendar"
                                        readonly v-bind="attrs" v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="cd" 
                                        @input="closeCD"
                                        year-icon="mdi-calendar-blank"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-btn block 
                            color="primary"
                            :loading="loading"
                            :disabled="loading"
                            @click="exportFile()"
                        >
                            ตกลง
                        </v-btn>
                    </v-form>

                </v-col>
            </v-row>

            <v-snackbar v-model="alert" top right :color="color">
                {{ message }}
                <template>
                    <v-btn text small fab @click="alert = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                </template>
            </v-snackbar>

        </v-app>
	</div>
</template>

<script>

	export default {
		name: 'Export',
        data: function() {
            return {
                valid: true,
                users: [],
                loading: false,
                data: 'cus',
                phone: '',
                firstname: '',
                caretaker: '',
                pickerCD: false,
                createdate: '',
                cd: null,
                alert: false,
                message: '',
                color: '',
            };
        },

        beforeMount(){
            this.getUser();
        },

        methods: {

            getUser: async function(){
                var app = this;
                var formData = new FormData();
                formData.append("mode", 'getUser')
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response.data
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        app.users = response.data
                    }
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
            },

            closeCD (){
                var app = this;
                app.pickerCD = false;
                app.createdate = app.cd ? this.$moment(app.cd).format('YYYY-MM-DD') : '';
            },

            exportFile: async function(){
                var app = this;
                app.loading = true
                var formData = new FormData();
                formData.append("data", app.data)
                formData.append("phone", app.phone)
                formData.append("firstname", app.firstname)
                formData.append("caretaker", app.caretaker)
                formData.append("createdate", app.createdate)
                formData.append("mode", 'export')
                // console.log(Object.fromEntries(formData))
                await this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = response.data
                        app.color = "error" //success
                        app.alert = true
                    }else{
                        if(response.data != ''){
                            // const dataWS = XLSX.utils.json_to_sheet(response.data, {skipHeader: 1})
                            const dataWS = app.$XLSX.utils.json_to_sheet(response.data)
                            const wb = app.$XLSX.utils.book_new()
                            app.$XLSX.utils.book_append_sheet(wb, dataWS)
                            app.$XLSX.writeFile(wb,'Export_'+app.data+'.xlsx')
                        } else {
                            app.message = 'ไม่พบข้อมูล'
                            app.color = "error" //success
                            app.alert = true
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
                app.loading = false
            },
        }
	}
</script>
