<template>
	<div class="Users">
        <v-app id="inspire">
            <v-row>
                <v-col>
                    <template>
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            sort-by="username"
                            class="elevation-1"
                            >

                            <template v-slot:top>
                                <v-form v-model="valid" ref="form" lazy-validation>
                                    <v-toolbar flat>

                                        <v-toolbar-title>AGENT</v-toolbar-title>
                                        <v-spacer></v-spacer>

                                        <!----------------Dialog Edit Agent ----------------->
                                        <v-dialog
                                            v-model="dialog"
                                            max-width="600px"
                                            persistent
                                        >
                                            <v-card>
                                            <v-card-title>
                                                <span class="text-h5">Manage Link</span>
                                            </v-card-title>

                                            <v-card-text>
                                                <v-container>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="editedItem.link1" label="แจ้งงาน"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field v-model="editedItem.link2" label="ผลงาน"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        Customer (Telephone) Ex. 081xxxxxxx,083xxxxxxx
                                                        <v-textarea v-model="editedItem.customer" solo></v-textarea>
                                                    </v-col>
                                                </v-row>
                                                </v-container>
                                            </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="close">
                                                    Cancel
                                                </v-btn>
                                                <v-btn color="blue darken-1" text @click="save(formTitle)">
                                                    Save
                                                </v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </v-dialog>

                                        <!----------------End Dialog Add User ----------------->

                                    </v-toolbar>
                                </v-form>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon small class="mr-2" @click="editItem(item)">
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <template v-slot:no-data>
                                no data
                            </template>

                        </v-data-table>
                    </template>

                    <v-snackbar v-model="alert" top right :color="color">
                        {{ message }}
                        <template>
                            <v-btn text small fab @click="alert = false"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
                        </template>
                    </v-snackbar>

                </v-col>
            </v-row>
        </v-app>
	</div>
</template>

<script>

	export default {
		name: 'Agent',
        data: function() {
            return {
                valid: true,
                dialog: false,
                dialogDelete: false,
                headers: [
                    { text: 'Name',align: 'start',sortable: true,value: 'firstname' },
                    { text: 'แจ้งงาน',align: 'start',value: 'link1' },
                    { text: 'ผลงาน',align: 'start',value: 'link2' },
                    { text: 'Actions', value: 'actions', sortable: false },
                ],
                items: [],
                editedIndex: -1,
                editedItem: {
                    username: '',
                    status: 'E',
                    resetPass: false,
                },
                defaultItem: {
                    username: '',
                    status: 'E',
                    resetPass: false,
                },
                nameRules: [
                    v => !!v || 'Required',
                ],
                showDelete:false,
                usernameRead:false,
                showPass:false,
                alert:false,
                message:'',
                color:'',
            };
        },

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'ADD' : 'EDIT'
            },
        },

        watch: {
            dialog (val) {
                if (this.$refs.form) {
                    this.$refs.form.resetValidation()
                }
                if( this.editedItem.username == ''){
                    this.usernameRead = false
                    this.showPass = false
                }else {
                    this.usernameRead = true
                    this.showPass = true
                }
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },

        created () {
            this.initialize()
        },

        methods: {
            initialize () {
                var app = this;
                let formData = new FormData();
                formData.append('mode', 'getAllAgent');
                this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = "ERROR Please contact admin"
                        app.color = "error"
                        app.alert = true
                    }else{
                        app.items = response.data 
                    }
                })
                .catch(function (error) {
                    app.message = error
                    app.color = "error"
                    app.alert = true
                });
            },

            editItem (item) {
                this.editedIndex = this.items.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                })
            },

            save () {
                var app = this;
                let formData = new FormData();
                formData.append('cid', app.editedItem.cid);
                formData.append('link1', app.editedItem.link1);
                formData.append('link2', app.editedItem.link2);
                formData.append('customer', app.editedItem.customer);
                formData.append('mode', 'manageAgent');
                // console.log([...formData])
                this.$axios.post(this.$hostUrl+'get/', formData)
                .then(function (response) {
                    if(!response.data){
                        app.message = "ERROR Please contact admin"
                        app.color = "error"
                        app.alert = true
                    }else{
                        // var output = response.data 
                        // console.log(output)
                        app.message = "Manage agent success"
                        app.color = "success"
                        app.alert = true
                        app.initialize()
                        app.close()
                    }
                })
                .catch(function (error) {
                    app.message = error
                    app.color = "error"
                    app.alert = true
                });

            },
        }
	}
</script>
