import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Import from '../views/Import.vue'
import Export from '../views/Export.vue'
import Manage from '../views/Manage.vue'
import Banner from '../views/Banner.vue'
import Users from '../views/Users.vue'
import Agent from '../views/Agent.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/import',
		name: 'Import',
		component: Import
	},
	{
		path: '/export',
		name: 'Export',
		component: Export
	},
	{
		path: '/manage',
		name: 'Manage',
		component: Manage
	},
	{
		path: '/banner',
		name: 'Banner',
		component: Banner
	},
	{
		path: '/users',
		name: 'Users',
		component: Users
	},
	{
		path: '/agent',
		name: 'Agent',
		component: Agent
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
